import { Atom, Cmp, Mod } from ":mods";
import { createStore, unwrap } from "solid-js/store";
import { For, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "../../../../models";

//? response and request payloads
type RecievedPayload = AMaterialTaskContent<{
  image: any;
  description: string;
}>;
type RequestPayload = AMaterialTaskContent<{
  image?: boolean;
  description?: string;
}>;
type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;
// -------------
//? potential exhibition venues
// -------------
export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // setup vars before content
  let images = [];

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    images = [];
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, props: RequestPayloadSectionContentItem) {
    const items = content()[0].content as unknown as RequestPayloadSectionContent;
    items[idx] = { ...items[idx], ...props };
    //? this is where you process data before passing it to update
    for (const item of items) {
      // const new_item = { ...item, ...props } as RequestPayloadSectionContentItem;
      if (typeof item.image !== "boolean") {
        item.image = false;
      }
    }
    // pass data to update
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    images.map((imagefile, i) => formData.append("images", imagefile, `tp01 image ${i + 1}`));
    formData.append("data", JSON.stringify(data));
    props.events?.onDataUpdate?.(formData, data);
  }

  return (
    <form class="w-full">
      <For each={content()}>
        {(section, index) => {
          return (
            <>
              <h1 class="text-39px font-700 mt-40px mb-20px">
                {section.details?.title || section.details[0]?.title || "Potential Exhibition Venues"}
              </h1>
              <section class="flex flex-wrap gap-2rem">
                <For each={section.content}>
                  {(content, idx) => {
                    return (
                      <>
                        <div class="flex justify-center gap-30px w-full h-full">
                          <div class="w-45% h-55vh">
                            {!!content.image && (
                              <Atom.Tasks.Image
                                class="w-full h-full"
                                options={{
                                  editable: props.options.editable,
                                }}
                                elements={{
                                  images: images,
                                  image: content.image,
                                }}
                                events={{
                                  onImageChange: (e) => onDataChange(idx(), { image: e }),
                                }}
                              />
                            )}
                          </div>
                          <div class="w-55% h-27vh">
                            <Atom.Tasks.Description
                              class="w-full h-full"
                              elements={{
                                description: content.description,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  }}
                </For>
              </section>
            </>
          );
        }}
      </For>
    </form>
  );
}
